import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import EuroIcon from "@mui/icons-material/Euro";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  CategoryImageStyle,
} from "./PromotionFlash.style";
import { Add, Delete, Edit } from "@mui/icons-material";
import { CONFIG } from "config/config";
import { useCallback, useEffect, useState } from "react";
import DeleteTax from "../PromotionFlashDelete/DeletePromotionFlash";
import AddTax from "../PromotionFlashAdd/AddFlashPromotion";
import EditTax from "../PromotionFlashEdit/EditPromotionFlash";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AddSubCategory from "features/SubCategory/SubCategoryAdd/AddSubCategory";
import { IPromotionFlashData } from "./PromotionFlash.types";
import usePromotionFlashList from "./usePromotionFlashList";
import AddFlashPromotion from "../PromotionFlashAdd/AddFlashPromotion";
import DeletePromotionFlash from "../PromotionFlashDelete/DeletePromotionFlash";
import EditPromotionFlash from "../PromotionFlashEdit/EditPromotionFlash";
import { Checkbox } from "@mui/material";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import { useSnackBar } from "hooks/useSnackBar";
import {
  useDeleteMultiplePromotionFlashsMutation,
  useDeletePromotionFlashMutation,
} from "redux/api/promotionFlash/promotionFlash";
import DeletePromotionFlashMultiple from "../PromotionFlashDeleteMultiple/DeletePromotionFlashMutiple";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import { isTimeStampsDateBetweenTwoDays2 } from "utils/helpers/global.helpers";
import TrashedSwitch from "UpdatedComponents/TrashedSwitch";
import { MdOutlineRestorePage } from "react-icons/md";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { restorePromotionFlash } from "UpdatedFeatures/api";
import RestoreModal from "UpdatedFeatures/RestoreModal";

function PromotionFlash() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    promotionFlashes,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    isArchived,
    setIsArchived,
    refetch,
  } = usePromotionFlashList();

  const [tax, setTax] = useState<any>({
    id: 0,
    product_id: "",
    date_promotion: "",
    percentage: "",
    created_at: "",
    updated_at: "",
  });
  const [deleteCode, { data, error, isLoading: loading, reset }] =
    useDeleteMultiplePromotionFlashsMutation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedTax, setDeletedTax] = useState<{
    id: number;
    product_id: string;
    date_promotion: string;
    percentage: string;
  }>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);
  };
  const deleteMultiple = (event: any) => {
    event.preventDefault();
    deleteCode({
      promo_flash_ids: selectedItems,
      select_all: selectAll,
      search: search,
    });
    if (!error) setOpenDeleteMultipleModal(false);
  };
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false);

  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [isAddSubCat, setIsAddSubCat] = useState(false);
  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (event.target.checked) {
      setCheckedItems((prevState: number[]) => [...prevState, id]);
    } else {
      setCheckedItems((prevState: number[]) =>
        prevState.filter((item: number) => item !== id)
      );
    }
  };
  const restoreMutation = useMutation({
    mutationFn: (id: number) => restorePromotionFlash(id),
    onSuccess: () => {
      toast.success(t("promo.restore_success"));
      refetch()
      setRestoreModalOpen(false);
    },
    onError: (error) => {
      toast.error(t("promo.restore_error"));
      setRestoreModalOpen(false);
    },
  });
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [promoToRestore, setPromoToRestore] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const handleRestoreClick = (promotion: IPromotionFlashData) => {
    setPromoToRestore({ id: promotion.id, name: promotion.percentage_value });
    setRestoreModalOpen(true);
  };

  const handleRestoreConfirm = () => {
    if (promoToRestore) {
      restoreMutation.mutate(promoToRestore.id);
    }
  };

  const openDeleteDialogStatus = useCallback(
    (
        id: number,
        product_id: string,
        date_promotion: string,
        percentage: string
      ) =>
      () => {
        setOpenDeleteDialog(true);
        setDeletedTax({
          id: id,
          product_id: product_id,
          date_promotion: date_promotion,
          percentage: percentage,
        });
      },
    [setOpenDeleteDialog, setDeletedTax]
  );
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);
  };

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("promo_flash.successfully_deleted"));
    reset();
  }
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const openAddingSubCatDialogStatus = useCallback(
    (tax: IPromotionFlashData) => {
      setOpenAddingDialog(true);
      setTax({ ...tax });
    },
    []
  );
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedTax, setEditedTax] = useState<{
    id: number;
    percentage_value: string;
    link: string;
    products: string;
    categories: string;
    subCategories: string;
    start_date: string;
    end_date: string;
    eur_value: string;
    subscriptions: string;
    eur_or_percentage: string;
    description: string;
    value_type: string;
    promotion_value: string;
    affectation: string;
  }>();

  const openEditingDialogStatus = useCallback(
    (
        id: number,
        percentage_value: string,
        link: string,
        products: string,
        categories: string,
        subCategories: string,
        start_date: string,
        end_date: string,
        eur_value: string,
        subscriptions: string,
        eur_or_percentage: string,
        description: string,
        value_type: string,
        promotion_value: string,
        affectation: string
      ) =>
      () => {
        setEditedTax({
          id,
          percentage_value,
          link,
          products,
          categories,
          subCategories,
          start_date,
          end_date,
          eur_value,
          subscriptions,
          eur_or_percentage,
          description,
          value_type,
          promotion_value,
          affectation,
        });
        setOpenEditingDialog(true);
      },
    []
  );

  return (
    <DashboardLayout
      title={t("promo_flash.page_title")}
      selectedPath={RouteIdEnum.Promo_Flash}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex  items-center gap-5">
          <TrashedSwitch enabled={isArchived} setEnabled={setIsArchived} />
          {(selectAll || selectedItems.length != 0) && (
            <DeleteButton
              onAddClick={openDeleteMultipleDialogStatus}
              title={t("common.delete")}
            />
          )}
          &nbsp;
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </div>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: (
              <Checkbox
                color="secondary"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            ),
          },
          {
            key: "description",
            title: t("Opération"),
            onClick: onOrderChange("description"),
            asc: orderBy === "description" ? orderDir === "asc" : undefined,
          },
          {
            key: "start_date",
            title: "Date de début",
            onClick: onOrderChange("start_date"),
            asc: orderBy === "start_date" ? orderDir === "asc" : undefined,
          },
          {
            key: "promotion_value",
            title: "Valeur de la promotion",
            onClick: onOrderChange("promotion_value"),
            asc: orderBy === "promotion_value" ? orderDir === "asc" : undefined,
          },
          {
            key: "value_type",
            title: "Type de promotion",
            onClick: onOrderChange("value_type"),
            asc: orderBy === "value_type" ? orderDir === "asc" : undefined,
          },
          {
            key: "end_date",
            title: "Date de fin",
          },
          {
            key: "created_at",
            title: t("category.created_at"),
          },
          {
            key: "status",
            title: t("status"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={promotionFlashes?.data.map((e: any) => {
          return {
            checkbox: (
              <Checkbox
                checked={
                  (!selectAll && selectedItems.includes(e.id)) ||
                  (selectAll && !selectedItems.includes(e.id))
                }
                onChange={() => handleCheckboxChange(e.id)}
              />
            ),
            description: <p>{`${e.description}`}</p>,

            start_date: (
              <div>
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hourCycle: "h23", // Forces 24-hour time format
                }).format(new Date(Number(e.start_date) * 1000))}
              </div>
            ),

            promotion_value: (
              <p>{`${e.promotion_value ? e.promotion_value : "0"}`}</p>
            ),
            value_type: (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                {e.value_type ? (
                  e.value_type === "percentage" ? (
                    <PercentIcon />
                  ) : e.value_type === "euro" ? (
                    <EuroIcon />
                  ) : null
                ) : (
                  "0"
                )}
              </Box>
            ),

            end_date: (
              <div>
                {new Intl.DateTimeFormat('en-GB', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hourCycle: 'h23', 
                }).format(new Date(Number(e.end_date)))}
              </div>
            ),

            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            status: (
              <StatusBadge
                isEnabled={isTimeStampsDateBetweenTwoDays2(
                  Number(e.start_date),
                  Number(e.end_date)
                )}
              />
            ),
            action: isArchived ? (
              <Tooltip title={"Restorer"} placement="top">
                <MdOutlineRestorePage
                  size={30}
                  className="text-[#b369ae] hover:cursor-pointer "
                  onClick={() => handleRestoreClick(e)}
                />
              </Tooltip>
            ) : (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(
                        e.id,
                        e.percentage_value,
                        e.link,
                        e.products,
                        e.categories,
                        e.subCategories,
                        e.start_date,
                        e.end_date,
                        e.eur_value,
                        e.subscriptions,
                        e.eur_or_percentage,
                        e.description,
                        e.value_type,
                        e.promotion_value,
                        e.affectation
                      )}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(
                        e.id,
                        e.product_id,
                        e.date_promotion,
                        e.percentage
                      )}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: promotionFlashes?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedTax && (
        <DeletePromotionFlash
          id={deletedTax.id}
          product_id={deletedTax.product_id}
          date_promotion={deletedTax.date_promotion}
          percentage={deletedTax.percentage}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeletePromotionFlash>
      )}
      <AddFlashPromotion
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddFlashPromotion>
      {selectedItems && (
        <DeletePromotionFlashMultiple
          isLoading={loading}
          promo_flash_ids={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeletePromotionFlashMultiple>
      )}
      {editedTax && (
        <EditPromotionFlash
          iniData={{
            id: editedTax.id,
            percentage_value: editedTax.percentage_value,
            start_date: editedTax.start_date,
            end_date: editedTax.end_date,
            link: editedTax.link,
            subscriptions: eval(editedTax.subscriptions),
            products: eval(editedTax.products),
            categories: eval(editedTax.categories),
            subCategories: eval(editedTax.subCategories),
            eur_value: editedTax.eur_value,
            eur_or_percentage: editedTax.eur_or_percentage,
            description: editedTax.description,
            value_type: editedTax.value_type,
            promotion_value: editedTax.promotion_value,
            affectation: editedTax.affectation,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditPromotionFlash>
      )}
      {promoToRestore && (
        <RestoreModal
          open={restoreModalOpen}
          onConfirm={handleRestoreConfirm}
          onCancel={() => setRestoreModalOpen(false)}
          itemTitle={promoToRestore.name}
        />
      )}
    </DashboardLayout>
  );
}
export default PromotionFlash;
