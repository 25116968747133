"use client";
import { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateProductArrivage } from "UpdatedFeatures/api";

const EditArrivageModal = ({ open, setOpen, selectedArrivage }) => {
    const [formData, setFormData] = useState(selectedArrivage);
    const [isLoading, setIsLoading] = useState(false);
    console.log(formData)
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: updateProductArrivage,
        onSuccess: () => {
            console.log("Arrivage updated successfully");
            queryClient.invalidateQueries('arrivage');
            toast.success("Arrivage mis à jour avec succès");
            setIsLoading(false);
            setOpen(false);
        },
        onError: (error) => {
            console.error("Error updating arrivage:", error);
            setIsLoading(false);
        },
    });

    useEffect(() => {
        if (selectedArrivage) {
            setFormData(selectedArrivage);
        }
    }, [selectedArrivage]);

    const handleInputChange = (index, field, value) => {
        setFormData({
            ...formData,
            products: formData.products.map((product, i) =>
                i === index ? { ...product, pivot: { ...product.pivot, [field]: value } } : product
            ),
        });
    };
    const handleSubmit = (e, product) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(product)
        mutation.mutate({
            arrivageId: selectedArrivage.id,
            productId: product.id,
            data: {
                kdQuantity: product.pivot.kdQuantity,
                price: product.pivot.price,
            },
        });
    };

    if (!open) return null;

    return (
        <div
            className="fixed inset-0 z-50"
            style={{ zIndex: 9991, overflow: "visible" }}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75"
                aria-hidden="true"
                onClick={() => setOpen(false)}
            />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div
                    className="relative w-full max-w-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
                        <h3 id="modal-title" className="text-lg font-medium leading-6 text-white">
                            Éditer Arrivage
                        </h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    <form className="space-y-4 p-6" onSubmit={handleSubmit}>
                        {formData?.products.map((product, index) => (
                            <div key={product.id}>
                                <label className="block text-sm  text-gray-700 font-semibold mb-4 underline">
                                    {product.title} (ID: {product.id})
                                </label>
                                <div className="flex space-x-4">
                                    <div className="w-full">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Quantité (kdQuantity)
                                        </label>
                                        <input
                                            type="number"
                                            value={product.pivot.kdQuantity}
                                            onChange={(e) => handleInputChange(index, 'kdQuantity', e.target.value)}
                                            required
                                            disabled={product.pivot.status == 1}
                                            className="mt-1 block w-full disabled:cursor-not-allowed rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Prix
                                        </label>
                                        <input
                                            type="number"
                                            value={product.pivot.price}
                                            onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                            required
                                            disabled={product.pivot.status == 1}
                                            className="mt-1 block w-full rounded-md disabled:cursor-not-allowed border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button
                                        onClick={(e) => handleSubmit(e, product)}
                                        type="submit"
                                        className="rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 bg-[#b269ae] px-4 py-2 text-white font-semibold shadow-sm hover:bg-[#9c58a3] duration-300"
                                        disabled={isLoading || product.pivot.status == 1}
                                    >
                                        {isLoading ? 'Loading...' : 'Sauvegarder'}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditArrivageModal;