import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addClient } from "../../../UpdatedFeatures/api";
import { toast } from "react-toastify";


const AddClientModal = ({ onClose, refetch }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    delivery_address: "",
    birth: "",
    gender: "",
    job: "",
    country: "",
    city: "",
    zip_code: "",
    foyer_composition: 0,
    housing_type: "",
    housing_status: "",
  });

  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = storedUserInfo?.data?.token;

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addClient,
    onSuccess: () => {
      toast.success("Client ajouté avec succes");
      console.log("User added successfully");
      onClose(false);
      refetch();
      queryClient.invalidateQueries(["clients", token]);
    },
    onError: (error) => {
      console.error("Error adding user:", error);
  
      if (error.response?.data?.error?.includes("EMAIL_UNIQUE")) {
        toast.info("L'email existe déjà");
      } else if (error.response?.data?.errors?.email?.includes("The email has already been taken.")) {
        toast.info("L'email existe déjà");
      } else {
        toast.error("Erreur lors de l'ajout d'un client");
      }
    },
  });
  

  const handleChange = (e) => {
    const { name, value, type } = e.target;
  
    setFormData({
      ...formData,
      [name]: type === "number" ? parseInt(value, 10) || 0 : value,
    });
  };
  
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.password_confirmation) {
        alert("Passwords do not match");
        return;
      }
    const birthTimestamp = new Date(formData.birth).getTime();
    const dataToSend = { ...formData, birth: birthTimestamp };
    mutation.mutate(dataToSend);
  };


  return (
    <div
      className="fixed inset-0 z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75"
        aria-hidden="true"
        onClick={() => onClose(false)} 
      />

      <div className="flex items-center justify-center max-h-[80%]  p-4 mt-24">
        <div
          className="relative w-full max-w-lg mx-auto bg-white overflow-y-auto max-h-[80vh]   p-6 shadow-lg rounded-lg overflow-hidden"
          onClick={(e) => e.stopPropagation()} 
        >
        
          <div className="flex justify-between items-center border-b border-gray-500/30 pb-4">
            <h3
              id="modal-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Ajouter un Client
            </h3>
            <button
              type="button"
              className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={() => onClose(false)}
            >
              <RiCloseLine className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>{" "}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-4 mt-8">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom <span className="text-red-600"> * </span>
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom<span className="text-red-600"> * </span>
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email<span className="text-red-600"> * </span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Téléphone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div className="col-span-full">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Mot de passe
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
              />
            </div>
            <div className="col-span-full">
              <label
                htmlFor="password_confirmation"
                className="block text-sm font-medium text-gray-700"
              >
                Confirmer le mot de passe
              </label>
              <input
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
              />
            </div>
              <div>
                <label
                  htmlFor="delivery_address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <input
                  id="delivery_address"
                  name="delivery_address"
                  type="text"
                  value={formData.delivery_address}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="birth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date de naissance
                </label>
                <input
                  id="birth"
                  name="birth"
                  type="date"
                  value={formData.birth}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Genre
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="job"
                  className="block text-sm font-medium text-gray-700"
                >
                  Profession
                </label>
                <input
                  id="job"
                  name="job"
                  type="text"
                  value={formData.job}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pays
                </label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="" disabled>
                    Sélectionner un pays
                  </option>
                  <option value="USA">États-Unis</option>
                  <option value="FRA">France</option>
                  <option value="ESP">Espagne</option>
                  <option value="DEU">Allemagne</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ville
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  value={formData.city}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="zip_code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code postal
                </label>
                <input
                  id="zip_code"
                  name="zip_code"
                  type="text"
                  value={formData.zip_code}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="foyer_composition"
                  className="block text-sm font-medium text-gray-700"
                >
                  Composition du foyer
                </label>
                <input
                  id="foyer_composition"
                  name="foyer_composition"
                  type="number"
                  value={formData.foyer_composition}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="housing_type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type de logement
                </label>
                <select
                  id="housing_type"
                  name="housing_type"
                  value={formData.housing_type}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="" disabled>
                    Sélectionner un type de logement
                  </option>
                  <option value="Appartement">Appartement</option>
                  <option value="Maison individuelle">
                    Maison individuelle
                  </option>
                  <option value="Hebergé">Hebergé</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="housing_status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Statut du logement
                </label>
                <select
                  id="housing_status"
                  name="housing_status"
                  value={formData.housing_status}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="" disabled>
                    Sélectionner un statut du logement
                  </option>
                  <option value="propriétaire">Propriétaire</option>
                  <option value="locataire">Locataire</option>
                  <option value="Hebergé à titre gratuit">
                    Hebergé à titre gratuit
                  </option>
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-[#b269ae] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#b269ae] focus:outline-none focus:ring-2  focus:ring-offset-2"
                onClick={() => onClose(false)}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-[#b269ae] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#b269ae] focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddClientModal;
