import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Button from "components/common/Button/Button";
import InputsContainer from "components/common/InputsContainer/InputsContainer";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  IExpeditionModesDialog,
  IExpeditionModesEditingDataError,
} from "./EditExpeditionMode.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import {
  useGetCategoriesValuesExpeditionModeQuery,
  useGetExpeditionModeByIdQuery,
  useUpdateExpeditionModeMutation,
} from "redux/api/delivery/expeditionMode/expeditionMode";
import { InputStyleCostMode } from "../ExpeditionModeAdd/AddExpeditionMode.style";
import Table from "components/common/Table/Table";
import {
  Autocomplete,
  FormControl,
  Grid,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import useTaxList from "features/Taxe/TaxeList/useTaxList";
function EditExpeditionMode({ codeInfos }: IExpeditionModesDialog) {
  const { t } = useTranslation();
  const { id }: any = useParams();

  const { data: categValue } = useGetCategoriesValuesExpeditionModeQuery({
    id: parseInt(id),
  });
  const { data: editingData } = useGetExpeditionModeByIdQuery({
    id: id,
  });
  console.log(editingData)
  const navigate = useNavigate();
  const [editingInfo, setEditingInfo] = useState<any>({
    start: "",
    end: "",
  });
  const { taxesData } = useTaxList();
  let CategValueArray = editingInfo?.categ_value?.map((categ: any) => ({
    category: categ.category,
    price: categ.price,
  }));
  console.log(taxesData)
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [editingError, setEditingError] =
    useState<IExpeditionModesEditingDataError>({
      title: "",
      start: "",
      end: "",
      tax_value: "",
      type: 0,
      categ_value: "",
    });
  const onChange = useCallback(
    (field: string) =>
      (value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = typeof value === "string" ? value : value.target.value;
        setEditingInfo({ ...editingInfo, [field]: newValue });
      },
    [editingInfo]
  );

  const onSelectChange = useCallback(
    (field: string) => (event: SelectChangeEvent<string>) => {
      const value = Number(event.target.value);
      setEditingInfo({ ...editingInfo, [field]: value });
    },
    [editingInfo, editingError]
  );
  useEffect(() => {
    setEditingInfo({ ...editingData, categ_value: categValue });
  }, [editingData, categValue]);

  const [editingCode, { data, error, reset }] =
    useUpdateExpeditionModeMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("delivery.successfully_editing_mod"));

    reset();
    navigate(-1);
  }
  const onChangeCategValue = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingInfo((prevState: any) => {
          // Create a new object with the existing properties
          const newObj = { ...prevState };
          console.log("newObj", newObj);
          // Create a new array with the updated element
          const newArray = [...newObj.categ_value];
          console.log("newArray", newArray);
          const elementToUpdate = newArray.findIndex((item) => {
            console.log("item", item?.category_shipping?.id, "field", field[0]);
            return item.category == parseInt(field);
          });
          console.log("elementToUpdate", elementToUpdate);
          newArray[elementToUpdate] = {
            ...newArray[elementToUpdate],
            category: parseInt(field),
            price: e.target.value,
          };
          // Update the nested array in the new object
          newObj.categ_value = newArray;

          console.log("newObj", newObj);
          return newObj;
        });
      },
    [editingInfo]
  );

  const validate = () => {
    const editingDataErrorMode: any = {
      title: "",
      start: "",
      end: "",
      tax_value: "",
      type: "",
      categ_value: "",
    };
    if (editingInfo.title == "") {
      editingDataErrorMode.title = "Mode obliagtoire";
    } else {
      editingDataErrorMode.title = "";
    }
    if (editingInfo.start == "") {
      editingDataErrorMode.start = "Date debut obliagtoire";
    } else {
      editingDataErrorMode.start = "";
    }
    if (editingInfo.tax_value == "") {
      editingDataErrorMode.tax_value = "Taxe obliagtoire";
    } else {
      editingDataErrorMode.tax_value = "";
    }
    if (editingInfo.end == "") {
      editingDataErrorMode.end = "Date fin obliagtoire";
    } else {
      editingDataErrorMode.end = "";
    }
    if (!editingInfo.categ_value) {
      editingDataErrorMode.categ_value =
        "Valeurs categorie d'expédition obliagtoire";
    } else {
      editingDataErrorMode.categ_value = "";
    }
    if (editingInfo.type == null) {
      editingDataErrorMode.type = "type obliagtoire";
      alert("ok");
    } else {
      editingDataErrorMode.type = "";
    }
    console.log("editingdataError", editingDataErrorMode);
    setEditingError(editingDataErrorMode);
  };

  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    validate();

    if (
      editingInfo.title &&
      editingInfo.start &&
      editingInfo.end &&
      editingInfo.tax_value
    ) {
      editingCode({ ...editingInfo });
    }
  };

  return (
    <DashboardLayout
      title={t("delivery.edit_mod")}
      selectedPath={RouteIdEnum.Expedition_Mode}
      backPath={RouteIdEnum.Expedition_Mode}
    >
      <InputsContainer title={t("delivery.info_add")}>
        <Grid container spacing={1}>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <FormControl fullWidth>
              <Select
                native
                value={editingInfo?.type?.toString()}
                onChange={onSelectChange("type")}
                label={t("delivery.type")}
                sx={{
                  borderColor: "#B269AE",
                }}
              >
                <option value="1">Lolo Drive</option>
                <option value="0">Livraison</option>
              </Select>
            </FormControl>
          </AutoInputsContainerStyle>

          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={editingInfo?.title}
              label={t("delivery.mode")}
              onChange={onChange("title")}
              error={t(editingError.title)}
            />
          </AutoInputsContainerStyle>

          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label={t("delivery.start_time")}
                value={
                  editingInfo.start ? dayjs(editingInfo.start, "HH:mm") : null
                }
                onChange={(newValue) => {
                  onChange("start")(newValue ? newValue.format("HH:mm") : "");
                }}
                ampm={false}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    error: Boolean(t(editingError.start)),
                    helperText: t(editingError.start),
                  },
                }}
              />
            </LocalizationProvider>
          </AutoInputsContainerStyle>

          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label={t("delivery.end_time")}
                value={editingInfo.end ? dayjs(editingInfo.end, "HH:mm") : null}
                onChange={(newValue) => {
                  onChange("end")(newValue ? newValue.format("HH:mm") : "");
                }}
                ampm={false}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    error: Boolean(t(editingError.end)),
                    helperText: t(editingError.end),
                  },
                }}
              />
            </LocalizationProvider>
          </AutoInputsContainerStyle>

          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            {taxesData && (
              <Autocomplete
                sx={{ width: "100%" }}
                options={taxesData?.data?.map((tax: any) => ({
                  id: tax.id,
                  name: tax.name,
                  percentage: tax.percentage,
                }))}
                getOptionLabel={(option: any) =>
                  option?.name ? `${option.name} (${option.percentage}%)` : ""
                }
                value={
                  taxesData?.data?.find(
                    (tax: any) =>
                      tax.percentage === editingData?.tax_value
                  ) || null
                }
                onChange={(event: any, newValue: any) => {
                  const newTaxValue = newValue ? newValue.value : "";
                  setEditingInfo((prev: any) => ({
                    ...prev,
                    tax_value: newTaxValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("delivery.tax")}
                    variant="outlined"
                    placeholder={t("delivery.select_tax")}
                    error={Boolean(t(editingError.tax_value))}
                    helperText={t(editingError.tax_value)}
                  />
                )}
              />
            )}
          </AutoInputsContainerStyle>
        </Grid>
      </InputsContainer>

      <InputsContainer title={t("delivery.categories")}>
        <Grid container spacing={1} marginTop={2}>
          <hr />

          <Table
            loader={false}
            headerData={[
              {
                key: "category",
                title: t("delivery.category"),
              },
              {
                key: "value",
                title: t("delivery.value"),
              },
            ]}
            bodyData={editingInfo?.categ_value?.map((categ: any) => {
              return {
                category: <p>{categ?.category_shipping?.name}</p>,
                value: (
                  <InputStyleCostMode>
                    <TextInput
                      value={parseFloat(categ.price).toString()}
                      type="number"
                      min={0}
                      defaultValue={0}
                      onChange={onChangeCategValue(
                        `${categ?.category_shipping?.id}`
                      )}
                    />
                  </InputStyleCostMode>
                ),
              };
            })}
          />
        </Grid>
      </InputsContainer>

      <Grid container spacing={1} style={{ justifyContent: "flex-end" }}>
        <AutoInputsContainerStyle item xs={4} md={4} lg={2}>
          <Button
            type="submit"
            onClick={(e: any) => onSuccessEditingDialog(e)}
            title={t("delivery.edit_mod")}
          />
        </AutoInputsContainerStyle>
      </Grid>
    </DashboardLayout>
  );
}

export default EditExpeditionMode;
