import { SelectChangeEvent } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { GlobalVariables } from "config/constant";
import { useSnackBar } from "hooks/useSnackBar";
import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { useGetAllCategoryQuery } from "redux/api/category/category";
import { getCategories } from "UpdatedFeatures/api";

export default function useCategoryList() {
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [search, setSearch] = useState<string>("");
  const [isArchived, setIsArchived] = useState(false);
  console.log(isArchived)
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    GlobalVariables.DefaultPerPage
  );
  const [orderBy, setOrderBy] = useState<string>("created_at");
  const [orderDir, setOrderDir] = useState<"" | "asc" | "desc">("desc");

  const { data: categoryData, error, isLoading, refetch } = useGetAllCategoryQuery(isArchived
    ? {
      withTrashed: 2,
      paginator: { search, page, perPage, orderBy, orderDir }
    } : {
      paginator: { search, page, perPage, orderBy, orderDir }
    });

  if (error) {
    displayErrorApiSnackBar(error);
  }

  const onPageChange = (page: number) => {
    setPage(page);
    setTimeout(() => {
      refetch();
    }, 300); 
};

  const onSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    setPage(1);
    if (typeof e === "string") {
      setSearch(e);
    } else {
      setSearch(e.target.value);
    }
  };

  const onPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(parseInt(String(event.target.value), 10));
    setPage(1);
  };

  const onOrderChange = (field: string) => () => {
    const order_dir =
      orderBy === field
        ? orderDir === "asc"
          ? "desc"
          : orderDir === "desc"
            ? ""
            : "asc"
        : "asc";
    setOrderDir(order_dir);
    setOrderBy(order_dir === "" ? "" : field);
  };

  return {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    categoryData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    refetch,
    isArchived,
    setIsArchived
  };
}
