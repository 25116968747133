"use client"
import { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getArrivage, updateProductArrivageStatus } from "UpdatedFeatures/api";
import { CiCircleCheck } from "react-icons/ci";
import { toast } from "react-toastify";

const ChangeArrivageStatusModal = ({ open, setOpen, selectedArrivage }) => {
    const [formData, setFormData] = useState(selectedArrivage);
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient()
    const { data } = useQuery({
        queryFn: () => getArrivage(),
        queryKey: ['arrivage']
    });
    const [Arrivage, setArrivage] = useState([]);
    console.log(selectedArrivage)
    useEffect(() => {
        if (data && data.data) {
            const filteredData = data.data.filter(item => item?.id == selectedArrivage?.id);
            setArrivage(filteredData);
        }
    }, [data, selectedArrivage]);
    console.log(Arrivage)
    const mutation = useMutation({
        mutationFn: updateProductArrivageStatus,
        onSuccess: () => {
            console.log("Product arrivage status updated successfully");
            const updatedProducts = formData.products.map(product => {
                if (product.id === selectedArrivage.id) {
                    return { ...product, pivot: { ...product.pivot, status: 1 } };
                }
                return product;
            });
            setFormData({ ...formData, products: updatedProducts });
            queryClient.invalidateQueries('arrivage');
            toast.success("Le produit est Réceptionné avec Succès");
            setIsLoading(false);
        },
        onError: (error) => {
            console.error("Error updating product arrivage status:", error);
            setIsLoading(false);
        },
    });

    useEffect(() => {
        if (selectedArrivage) {
            setFormData(selectedArrivage)
        }
    }, [selectedArrivage]);

    const handleSubmit = (e, id) => {
        e.preventDefault();
        setIsLoading(true);
        mutation.mutate({
            arrivageId: selectedArrivage.id,
            productId: id,
        });
    };

    if (!open) return null;

    return (
        <div
            className="fixed inset-0 z-50"
            style={{ zIndex: 9991, overflow: "visible" }}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75"
                aria-hidden="true"
                onClick={() => setOpen(false)}
            />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div
                    className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
                        <h3 id="modal-title" className="text-lg font-medium leading-6 text-white">
                            Réceptionner Arrivage
                        </h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    <form className="space-y-4 p-6">
                        {Arrivage[0]?.products.map((product) => (
                            <div key={product.id}>
                                <label className="block text-sm font-medium text-gray-700">
                                    {product.title} (ID: {product.id})
                                </label>
                                <input
                                    type="number"
                                    disabled
                                    value={product?.pivot?.kdQuantity}
                                    required
                                    className="mt-1 block disabled:cursor-not-allowed w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                                />
                                <div className="mt-6 flex justify-end gap-6">
                                    {
                                        product?.pivot?.status == 1 ?
                                            <button
                                                type="button"
                                                className="rounded-md bg-green-600/10 px-3 py-1 text-center text-sm font-semibold border border-green-600 text-green-600 shadow-sm duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-not-allowed flex items-center gap-2"
                                            >
                                                <CiCircleCheck size={20} /> Réceptionné
                                            </button> :
                                            <button
                                                onClick={(e) => handleSubmit(e, product.id)}
                                                className="block rounded-md bg-[#b269ae] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                                                disabled={isLoading}
                                            >
                                                 Confirmer Réception
                                            </button>
                                    }
                                </div>
                            </div>
                        ))}

                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeArrivageStatusModal;