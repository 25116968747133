import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "redux/api/auth/auth";
import { useSnackBar } from "hooks/useSnackBar";
import { saveTokenToLocalStorage } from "utils/services/storage.service";
import imglogin from "assets/img/imglogin.jpeg";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

export default function Login() {
  const [data, setData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const { displayErrorApiSnackBar } = useSnackBar();
  const [login, { data: dataLogin, error: errorLogin, isLoading }] = useLoginMutation();
  console.log(dataLogin)
  useEffect(() => {
    if (dataLogin?.data?.user) {
      localStorage.setItem("userData", JSON.stringify(dataLogin.data.user));
    }
  }, [dataLogin]);
  const onChange = useCallback(
    (field) => (e) => {
      setData({ ...data, [field]: e.target.value });
    },
    [data]
  );
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  console.log(errorLogin)
  useEffect(() => {
    if (errorLogin) {
      toast.warn("Détails invalides ou connexion échouée");
    }
  }, [errorLogin]);

  useEffect(() => {
    if (dataLogin) {
      saveTokenToLocalStorage(dataLogin.data.token);
      localStorage.setItem("userData", JSON.stringify(dataLogin.data.user));
      
      
      navigate(`/`);
    }

  }, [dataLogin, navigate]);
  console.log(dataLogin)
  const onSubmitLogin = (event) => {
    event.preventDefault();
    login({ email: data.email, password: data.password });
  };

  return (
    <div className="flex min-h-screen flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-16 w-auto" src="/logo.jpeg" alt="Votre entreprise" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Connectez-vous à votre compte
            </h2>
          </div>
          <div className="mt-10">
            <form onSubmit={onSubmitLogin} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Adresse email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={onChange("email")}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mot de passe
                </label>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    onChange={onChange("password")}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                  >
                    {showPassword ? (
                      <FaEyeSlash size={20} color="black" />
                    ) : (
                      <FaEye size={20} color="black" />
                    )}
                  </button>
                </div>
              </div>


              <div>
                <button
                  type="submit"
                  className="flex w-full duration-300 justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  {isLoading ? "Loading..." : "Se connecter"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img className="absolute inset-0 h-full w-full " src={imglogin} alt="" />
      </div>
    </div>
  );
}
